import React from 'react';
import { Button } from 'react-bootstrap';
import { navigate } from 'gatsby';
import { isLoggedIn } from 'services/auth.api';
import MainLayout from '../layouts/mainLayout';
import str_img_or_1 from '../assets/str_img_or_1.png';
import str_img_or_2 from '../assets/str_img_or_2.png';
import str_img_or_3 from '../assets/str_img_or_3.png';
import { OptimizedImage } from '../components/common/OptimizedImage';
import HowItemCard from '../components/common/InfoItemsCard/InfoItemsCard';

// Bootstrap component imports

const howItems = [
  {
    imgSrc: str_img_or_1,
    imgAlt: 'Recyclable',
    imgTitle: 'Recyclable',
    title: 'Recyclable & Compostable Packaging',
    description:
      'Our goal has always been plastic free pet food. We’ve started with 60% reduced plastic, recyclable and compostable materials... and we will keep getting better.',
  },
  {
    imgSrc: str_img_or_2,
    imgAlt: 'packaging',
    imgTitle: 'packaging',
    title: 'Smart Packaging',
    description:
      'We’ve designed a whole packaging system that minimises all of the packaging materials in getting pet food to you. Not just the bits you see.',
  },
  {
    imgSrc: str_img_or_3,
    imgAlt: 'NZdesigned',
    imgTitle: 'NZdesigned',
    title: 'New Zealand designed and made',
    description:
      'Everything we make is sourced, made and supplied in New Zealand, including our packaging. So we use far less carbon miles, than all the imported brands.',
  },
];

export default () => {
  return (
    <MainLayout withTitle>
      <div className="banner ourStry-image str-banner">
        {/* <OptimizedImage fileName="story_banner_bg.png" /> */}
        <div className="container-fluid">
          <div className="banner-content">
            <h5>Why we made NZs best dog food?</h5>
            <h2>
              Life’s busy.
              <br />
              Pets are Family.
              <br />
              Our planet needs protecting.
            </h2>
          </div>
        </div>
      </div>
      <div className="features-wrapper mgn-sm-top-40">
        <div className="container-fluid">
          <div className="teat-bg">
            <div className="section-item def-w-max">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="txt-wrap-sect pdd-rt-50">
                    <h2 className="mgn-bot-30">
                      We need simple, healthy, sustainable pet food...
                    </h2>
                    <p className="para-txt mgn-bot-50">
                      Hi, we're Ben and Amy Rennell, Co-founders of Feed My
                      Furbaby. Jack has always been our Furbaby. But when kids
                      came along and life got busier, we found ourselves
                      constantly running out of dog food. <br />
                      After a busy family morning, and a mad dash to the shop...
                      we decided we’d had enough... “why isn’t there a
                      subscription pet food service in New Zealand?”. So we
                      decided to build it.
                      <br />
                      <br />
                      Then we opened our eyes! As we started to look at the pet
                      food market we realised its a huge global category
                      dominated by poor nutrition, imported brands, thick
                      plastic packaging and little regard for the planet. <br />
                      <br />
                      We set about, not just creating convenient, natural,
                      sustainable pet food, but leading a huge global industry
                      towards plastic free pet food.
                    </p>
                    <div className="para-bold">
                      <p>
                        Our mission is helping humans be the best pet parents we
                        can be. That means taking better care of our pets, our
                        planet and making it so easy you don’t even have to
                        think about it.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="op-img-wrap">
                    <OptimizedImage fileName="str_img_1.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="how-wrapper"
        data-title="Buy dog food Online NZ. Dog food subscription delivery - Furbaby"
      >
        <div className="container-fluid">
          <div className="how-hdr">
            <h2 className="mgn-bot-20">
              Tackling Plastic Packaging in Pet Food
            </h2>
          </div>
          <div className="how-card def-w-max pdd-top-40">
            <div className="row justify-content-center">
              {howItems.map((item, index) => (
                <HowItemCard
                  key={index}
                  imgSrc={item.imgSrc}
                  imgAlt={item.imgAlt}
                  imgTitle={item.imgTitle}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="features-wrapper mgn-bot-100">
        <div className="container-fluid">
          <div className="def-w-max">
            <div className="treats-3-bg">
              <div className="section-item def-w-max no-mgn">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="op-img-wrap">
                      <OptimizedImage fileName="str_img_2.png" />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="txt-wrap-sect">
                      <h2 className="mgn-bot-30">It’s our passion</h2>
                      <p className="para-txt mgn-bot-50">
                        You only need to look at our packaging and you can see
                        it’s different to anything else in the pet food
                        market... a lot of love has gone into it. <br />
                        <br />
                        We’re a kiwi fur-family just like you and we look at
                        everything as both pet parents and human parents wanting
                        to leave our kids a better world.
                      </p>
                      <Button
                        className="btn-def"
                        onClick={() =>
                          navigate(
                            isLoggedIn()
                              ? '/account/dashboard/'
                              : '/getstarted/',
                          )
                        }
                      >
                        {isLoggedIn() ? 'Dashboard' : 'Get started now'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fs-banner">
        <OptimizedImage fileName="dog_banner_1.png" />
      </div>
    </MainLayout>
  );
};
